import React from 'react'
import { PryButton } from '../../shared/buttons'

const Reach = () => {
  return (
    <div className='flex flex-col gap-4 justify-center items-center py-3 md:-mb-8 z-[90]'>
        <p className='tex-center font-Rale text-[#111111] text-[14px]'>Stay up to date with the latest courses</p>
        <form action="" className='rounded-2xl shadow-2xl p-4 w-full md:w-[425px] flex justify-between'>
            <input type="email" placeholder='email' className='w-[80%]' />
            <PryButton
                type={'submit'}
                text={'Send'}
                textSize={12}
                isItalic={true}
                isWhite={false}
                className={'px-[20px] py-[8px]'}
                />
        </form>
    </div>
  )
}

export default Reach