import React from 'react'
import Container from '../../shared/Container'
import { Link } from 'react-router-dom'
import Banner from '../../assets/images/footerShape.png'
import { footerdata } from '../../../utils/data'
import Location from '../../assets/icons/Location.svg'
import Call from '../../assets/icons/Call.svg'
import Email from '../../assets/icons/Groupemail.svg'
import Time from '../../assets/icons/Time Circle.svg'

const Footer = () => {


  return (
    <div id='footer' style={{backgroundImage: `url(${Banner})`}} className='bg-cover bg-center py-20 z-10'>
        <Container>
            <div className=''>
                <div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-0'>
                    <div className=' flex items-start flex-col lg:flex-row'>               
                        <div className='space-y-6'>
                          <Link to={'/'} className='text-white font-[900] font-Rale text-[30px]'>
                              Global Educom
                          </Link>
                          <p className='text-white font-Rale w-[90%]'><span className='font-bold'>At Global Educom!</span> We are committed to helping you succeed in your profession and postgraduate studies. With our expert support and 100% research success guarantee, you can be confident that you will reach your academic and professional goals.</p>
                        </div>
                    </div>

                    <div className=' flex flex-row justify-between gap-8 lg:gap-0'>
                        {
                            footerdata.map((item,i)=>{
                                return(
                                <div key={i} className='space-y-4 flex-1'>
                                    <p className='font-Rale text-white font-[700] text-[20px] text-left'>{item.title}</p>
                                    {
                                        item.children.map((child,id)=>{
                                            return(
                                                        <ul className='space-y-3 w-full'>
                                                            <li className='w-full flex  lg:justify-normal lg:items-start ' ><Link className=' w-full text-left font-Rale text-white font-[400] text-[14px] ' to={child.url}>{child.name}</Link></li>
                                                        </ul>
                                            )
                                        })
                                    }
                                    
                                </div>                            
                                )
                            })
                        }

                    </div>    

                    <div className='space-y-4'>
                        <p className='font-Rale text-white font-[700] text-[20px] text-left flex'><img src={Location} alt="icon" className='mr-2' />Address:</p>
                        
                        <ul className='space-y-3 w-full'>
                            <li className='w-full flex  lg:justify-normal lg:items-start text-left font-Rale text-white font-[400] text-[14px]' >Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
                            <li className='w-full flex  lg:justify-normal lg:items-start text-left font-Rale text-white font-[400] text-[14px]' ><img src={Call} alt="icon" className='mr-2' />Tel :+9229341037</li>
                            <li className='w-full flex  lg:justify-normal lg:items-start text-left font-Rale text-white font-[400] text-[14px]' ><img src={Time} alt="icon" className='mr-2' />Response hours: 8 to 20</li>
                            <li className='w-full flex  lg:justify-normal lg:items-start text-left font-Rale text-white font-[400] text-[14px]' ><img src={Email} alt="icon" className='mr-2' />Email: info@onlearn.com</li>
                        </ul>
                    </div>                
                </div>
              
            </div>

        
        </Container>

    </div>
  )
}

export default Footer