export const HeadingText = (props) => {
	const { text, className } = props;
	return (
		<h1 className="text-primary text-[28px] md:text-[36px] font-[700] font-Inter text-center mb-8">
			{text}
			<img
				src="images/svg/arc.svg"
				alt="arctan"
				className="absolute right-[25%] md:right-[35%]"
			/>
		</h1>
	);
};
