import React, { useState } from "react";
import Container from "../../shared/Container";
import { workData } from "../../../utils/data";
import { HeadingText } from "../../shared/Typographs";

const HowItWorks = () => {
	const [active, setActive] = useState("order");

	const WorkTab = ({ name, text, url }) => {
		return (
			<button
				onClick={() => setActive(name)}
				className={`rounded-[5px] italic font-Rale text-[12px]  py-[8px] px-[20px] transition-all ease-in-out duration-500  ${
					active === name
						? "text-white bg-primary"
						: "bg-transparent border border-[#C4C4C4] text-[#C4C4C4]"
				}`}
			>
				{text} &rarr;
			</button>
		);
	};

	return (
		<div className="py-[3rem]">
			<Container>
				<div>
					<HeadingText text="How It Works" />
				</div>

				<div className="flex justify-center items-center w-full gap-5 flex-wrap">
					{workData?.map((item, ind) => {
						return (
							<WorkTab
								key={ind}
								text={item.title}
								name={item.name}
								url={item.linkUrl}
							/>
						);
					})}
				</div>
			</Container>
		</div>
	);
};

export default HowItWorks;
