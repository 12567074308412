import React from "react";
import Container from "../../shared/Container";
import { PryButton } from "../../shared/buttons";

const CoreValueItem = ({ text }) => (
	<div className="flex items-center mb-2">
		<img src="images/svg/star.svg" alt="" />
		<div>
			<p className="font-[500] text-[14px] italic leading-[16.8px] tracking-[2%] ml-2">
				{text}
			</p>
		</div>
	</div>
);
const Core = () => {
	return (
		<Container>
			<div className="bg-[#DF1C25] w-[100%] md:w-[80%] mr-auto ml-auto rounded-[16px] md:relative">
				<div className="flex flex-col md:flex-row justify-between items-start">
					<div className="text-white pl-[20px] md:pl-[60px] pr-[30px] md:pr-0 pt-[60px] w-[100%] md:w-[48%]">
						<h2 className="font-[700] italic text-[30px] md:text-[48px] mb-8 leading-[30px] md:leading-[58.4px] md:tracking-[6%]">
							Core Values At Global Educom:
						</h2>
						<CoreValueItem text="Commitment to Excellence ………… 99.99%" />
						<CoreValueItem text="Research Success Guarantee  …………100%" />
						<CoreValueItem text="Professionalism …………99.99%" />
						<CoreValueItem text="Customer’s Satisfaction  …………99.5%" />
						<CoreValueItem text="Innovation  …………100%" />
						<PryButton
							text={"Start now"}
							textSize={16}
							isItalic={true}
							isWhite={true}
							className={"px-[30px] py-[10px] inline-block mt-6 mb-10 border-0"}
						/>
					</div>
					<img
						src="images/png/coreImage.png"
						alt="core-img"
						className=" w-[80%] md:w-[47%]  md:mt-[-100px] mr-auto ml-auto md:ml-0 md:mr-[-16px] md:absolute md:bottom-0 md:right-0 md:self-center"
					/>
				</div>
			</div>
		</Container>
	);
};

export default Core;
