import React from 'react'
import HeroImg from '../../assets/images/HeroImg.png'
import { PryButton } from '../../shared/buttons'
import { Link } from 'react-router-dom'
import Container from '../../shared/Container'

const Hero = () => {
  return (
    <div className="relative bg-cover bg-center w-full h-[100vh] flex z-[-1]" style={{backgroundImage: `url(${HeroImg})`}}>
      <div className="absolute inset-0 bg-gradient-to-r from-white via-white to-transparent h-full w-full z-[-1]"></div>
      {/* Your content here */}

      <Container>
        <div className='flex h-full'>
          <div className='flex-1 flex flex-col gap-10 h-full justify-center items-start '>
            <h1 className='text-primary font-[700] text-[40px] md:text-[54px] font-Rale leading-[60px]'>100% Research <br /> Success Guaranteed</h1>

            <p className='font-[400] font-Rale text-[#565657] italic text-[20px] w-[90%]'>Boost your preparation with our complete collection of proposals, research samples, ensuring you're fully ready for your upcoming presentation. Your curiosity and success is our priority.</p>

            <Link to={'/'}>
            <PryButton 
                text={'Start now'}
                textSize={12}
                isItalic={true}
                isWhite={false}
                className={'px-[20px] py-[8px]'}
                />
            </Link>
          </div>
          <div className='flex-1 hidden lg:block'>

          </div>            
        </div>
      
      </Container>


    </div>
  )
}

export default Hero


