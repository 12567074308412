import React from "react";
import Container from "../../shared/Container";

const AboutItem = ({ heading, text, fontSize, bold }) => {
	const style = {
		fontSize: fontSize ? fontSize : "18px", // Default font size is 18px
		color: "rgb(92, 92, 92)",
	};
	return (
		<div className="bg-[rgb(255,218,68,0.4)] rounded-[29px] p-9 italic">
			<h2 className="text-[#108A00] font-[800] text-[40px] leading-[46.96px] tracking-[0.5%] mb-4 pb-2 border-b-[5px] border-solid border-[#DF1C25] inline-block">
				{heading}
			</h2>
			<p className="italic" style={style}>
				{bold && <strong>Global Educom</strong>} {text}
			</p>
		</div>
	);
};

const About = () => {
	return (
		<Container>
			<div className="flex flex-col md:flex-row gap-7 md:gap-5 justify-between mt-[150px] mb-[150px] w-full md:w-[80%] mr-auto ml-auto md:items-stretch">
				<div className=" w-[100%] md:w-[45%] flex">
					<AboutItem
						heading={"About"}
						text={
							"is a leading digital provider of academic and professional support services in Nigeria. We provide postgraduates with all the resources and support they need to succeed in their career and academics. Stress no more…make Global Educom your no.1 go-to-career and academic solution."
						}
						fontSize={"28px"}
						bold={true}
					/>
				</div>
				<div className="flex flex-col gap-7 w-[100%] md:w-[40%]">
					<AboutItem
						heading={"Mission"}
						text={
							"We are dedicated to provide comprehensive support services, fostering research and proposal success, and helping our clients attain their educational and career aspirations. Your journey to excellence starts here!"
						}
						fontSize={"18px"}
					/>
					<AboutItem
						heading={"Vision"}
						text={
							"We envision a future where every student and professional thrives and achieves their highest potential through our unwavering commitment to academic and career excellence. Setting new standards of success and impact in Nigeria and beyond."
						}
						fontSize={"18px"}
					/>
				</div>
			</div>
		</Container>
	);
};

export default About;
