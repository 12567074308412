import ST1 from '../components/assets/icons/st1.svg'
import ST2 from '../components/assets/icons/st2.svg'
import ST3 from '../components/assets/icons/st3.svg'
import ST4 from '../components/assets/icons/st4.svg'

import Prof1 from '../components/assets/images/prof.png'
import Prof2 from '../components/assets/images/prof2.png'
import Prof3 from '../components/assets/images/prof3.png'
import Prof4 from '../components/assets/images/prof4.png'
import Prof5 from '../components/assets/images/prof5.png'

import Test1 from '../components/assets/images/test1.png'
import Test2 from '../components/assets/images/test2.png'
import Test3 from '../components/assets/images/test3.png'

export const navLinks = [
	{
		name: "Home",
		url: "/",
		id: "home",
	},
	{
		name: "Career",
		url: "/career",
		id: "career",
	},
	{
		name: "Blog",
		url: "/blog",
		id: "blog",
	},
	{
		name: "About Us",
		url: "/about",
		id: "about",
	},
];

export const profServiceData = [
	{
		imgUrl: Prof1,
		title: "Proposal Samples 1",
		desc: "We provide all levels of proposals that will provide answers to  your curiosity or work to succeed.",
		linkUrl: "/",
	},
	{
		imgUrl: Prof2,
		title: "Postgraduate Support Services",
		desc: "Your experience is special as a Postgraduate. We offer personalized support to help you from research to your thesis.",
		linkUrl: "/",
	},
	{
		imgUrl: Prof3,
		title: "Information on term/semester paper assistance",
		desc: "Covers topic selection, research, structural guidance, content review, editing, final polishing, revision support, and plagiarism checks, all aimed at improving your academic writing and research skills for exceptional results.",
		linkUrl: "/",
	},
	{
		imgUrl: Prof4,
		title: "Editorial Services Details",
		desc: "we improve your academic work with comprehensive proofreading, language enhancement, structure refinement, citation checks, plagiarism verification, feedback integration, and punctual delivery, ensuring excellent results.",
		linkUrl: "/",
	},
	{
		imgUrl: Prof5,
		title: "Variety of Services",
		desc: "We cater for  every postgraduate at all levels, offering a wide range of services, including: assignments, research projects, term papers, and more. Our expert team is your assurance of capable hands.",
		linkUrl: "/",
	},

];
export const workData = [
	{
		title: "Order Now",
		name: "order",
		linkUrl: "/",
	},
	{
		title: "Select the Right Order",
		name: "select",
		linkUrl: "/",
	},
	{
		title: "Contact a Consultant",
		name: "contact",
		linkUrl: "/",
	},
	{
		title: "Recieve your work",
		name: "receive",
		linkUrl: "/",
	},
];
export const standOutData = [
	{
		imgUrl: ST1,
		title: "100% Originality",
		desc: "We take humble-pride in providing insightful, brief and free from plagiarism academic and professional support to postgraduates like you. ",
	},
	{
		imgUrl: ST2,
		title: "Quick Delivery",
		desc: "We don’t joke with deadlines, our server is wired to provide you your requested samples in seconds without waiting period. ",
	},
	{
		imgUrl: ST3,
		title: "Accessibility",
		desc: "From the registration stage to activation of your account to order stage are made easy. Access freely.",
	},
	{
		imgUrl: ST4,
		title: "Full Customer Support",
		desc: "Our Customer Care Reps are always ready to solve every issue and attends to your requests 24/7.",
	},
];
export const testimonialData = [
	{
		imgUrl: Test1,
		name: "Bola Samson",
		desc: "Global Educom is a trusted source, their dedication to producing exceptional content reflects in every interaction, ensuring clients' complete satisfaction.",
	},
	{
		imgUrl:Test2,
		name: "Anonymous",
		desc: "I can confidently say that Global Educom consistently delivers on their promise of uniqueness and professionalism.",
	},
	{
		imgUrl:Test3,
		name: "David Irebor",
		desc: "Global Educom's dedication to producing top-notch content is evident in their unwavering commitment to originality and professionalism.",
	},
	{
		imgUrl: Test1,
		name: "Bola Samson",
		desc: "Global Educom is a trusted source, their dedication to producing exceptional content reflects in every interaction, ensuring clients' complete satisfaction.",
	},
	{
		imgUrl:Test2,
		name: "Anonymous",
		desc: "I can confidently say that Global Educom consistently delivers on their promise of uniqueness and professionalism.",
	},
	{
		imgUrl:Test3,
		name: "David Irebor",
		desc: "Global Educom's dedication to producing top-notch content is evident in their unwavering commitment to originality and professionalism.",
	},

];
export const faqData = [
	{
		question:
			"What if I encounter an issue while registering, how do I get it sorted out?",
		answer:
			"Our Customer Care Support Team is 24/7 available to attend to you, kindly contact us.",
	},
	{
		question:
			"What if I encounter an issue while registering, how do I get it sorted out?",
		answer:
			"Our Customer Care Support Team is 24/7 available to attend to you, kindly contact us.",
	},
	{
		question:
			"What if I encounter an issue while registering, how do I get it sorted out?",
		answer:
			"Our Customer Care Support Team is 24/7 available to attend to you, kindly contact us.",
	},
	{
		question:
			"What if I encounter an issue while registering, how do I get it sorted out?",
		answer:
			"Our Customer Care Support Team is 24/7 available to attend to you, kindly contact us.",
	},
	{
		question:
			"What if I encounter an issue while registering, how do I get it sorted out?",
		answer:
			"Our Customer Care Support Team is 24/7 available to attend to you, kindly contact us.",
	},
	{
		question:
			"What if I encounter an issue while registering, how do I get it sorted out?",
		answer:
			"Our Customer Care Support Team is 24/7 available to attend to you, kindly contact us.",
	},
];

export const footerdata = [
	{
		title: "Categories",
		children: [
			{
				name: "Home",
				url: "/",
			},
			{
				name: "Student",
				url: "/",
			},
			{
				name: "Writers",
				url: "/",
			},
			{
				name: "Admin",
				url: "/",
			},
		],
	},
	{
		title: "Links",
		children: [
			{
				name: "About Us",
				url: "/",
			},
			{
				name: "Blogs",
				url: "/",
			},
		],
	},
];
