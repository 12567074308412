import React from "react";
import { PryButton } from "../../shared/buttons";
import { Link } from "react-router-dom";

const PSCard = ({ img, url, title, desc }) => {
	return (
		<div className="bg-white flex flex-col w-full sm:w-[260px] lg:h-[450px] h-[400px] shadow-xl">
			<div className="w-full h-[40%]">
				<img src={img} className="object-cover w-full h-full" alt="new" />
			</div>
			<div className="p-4 flex flex-col justify-between w-full h-[60%]">
				<h3 className="text-[18px] text-primary font-[800] font-Rale italic tracking-wide">
					{title}:
				</h3>
				<p className="text-[#4D4D4D] text-[12px] font-[400] font-Rale italic ">
					{desc}
				</p>
				<Link to={url}>
					<PryButton
						text={"Start now"}
						textSize={12}
						isItalic={true}
						isWhite={false}
						className={"px-[20px] py-[8px]"}
					/>
				</Link>
			</div>
		</div>
	);
};

export default PSCard;
