import React from "react";
import { HeadingText } from "../../shared/Typographs";
import Container from "../../shared/Container";
import { profServiceData } from "../../../utils/data";
import PSCard from "./PSCard";
import { Swiper, SwiperSlide } from "swiper/react";

const ProfessionalServices = () => {
	return (
		<Container>
			<div className="py-[3rem] md:py-[5rem]">
				<div>
					<HeadingText text="Highly Rated Academic/ Professional Support Services " />
				</div>

				<Swiper
					spaceBetween={10}
					slidesPerView={5}
					autoplay={{ delay: 3000 }}
					breakpoints={{
						// When window width is >= 640px
						375: {
							slidesPerView: 1,
						},
						425: {
							slidesPerView: 2,
						},
						640: {
							slidesPerView: 2,
						},
						// When window width is >= 768px
						768: {
							slidesPerView: 3,
						},
						// When window width is >= 1024px
						1024: {
							slidesPerView: 3,
						},
						// When window width is >= 1280px
						1280: {
							slidesPerView: 4,
						},
						// When window width is >= 1536px
						1536: {
							slidesPerView: 5,
						},
					}}
				>
					{profServiceData?.map((item, ind) => (
						<SwiperSlide key={ind}>
							<PSCard
								img={item.imgUrl}
								title={item.title}
								desc={item.desc}
								url={item.linkUrl}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</Container>
	);
};

export default ProfessionalServices;
