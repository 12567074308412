import React from 'react'

const StandCard = ({icon, title, desc}) => {
  return (
    <div className='flex flex-col justify-between p-6 transition-all ease-in-out duration-500c h-[270px] w-full md:w-[45%] lg:w-[23%] items-center hover:bg-white hover:shadow-lg '>
        <img src={icon} alt="standout Icon" />
        <p className='font-[600] font-Rale italic text-primary text-[24px]'>{title}:</p>
        <p className='italic text-center font-[500] font-Rale text-[16px] text-[#696984]'>{desc}</p>
    </div>
  )
}

export default StandCard