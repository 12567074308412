import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import ProfessionalServices from "../../components/home/professionalService";
import StandOut from "../../components/home/standout";
import Hero from "../../components/home/hero";
import HowItWorks from "../../components/home/howItworks";
import Testimonials from "../../components/home/testimonials";
import FAQ from "../../components/home/faq";
import Reach from "../../components/home/reach";
import About from "../../components/home/about";
import Core from "../../components/home/core";

const Home = () => {
	return (
		<MainLayout>
			<Hero />
			<HowItWorks />
			<ProfessionalServices />
			<StandOut />
			<Testimonials />
			<About />
			<Core />
			<FAQ />
			<Reach />
		</MainLayout>
	);
};

export default Home;
