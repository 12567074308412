import React from 'react'
import { faqData, testimonialData } from '../../../utils/data'
import { HeadingText } from '../../shared/Typographs'
import Question from '../../assets/icons/question.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../../shared/Container';

const FAQ= () => {
    const FaqCard =({question, answer})=>{
        return(
            <div className='flex flex-col justify-center gap-4 md:gap-6 items-center md:justify-normal md:items-start md:flex-row bg-white shadow-xl rounded-2xl px-6 py-4 w-full md:w-[602px] md:h-[194px]'>
                <div className='w-[10%]'>
                    <img src={Question} alt="" />
                </div>

                <div className='flex flex-col space-y-4 w-[90%]'>
                    <h1 className='font-Rale font-[600] text-[18px] md:text-[22px] text-[#111111] '>{question}</h1>
                    <p className='font-Rale italic font-[400] text-[14px] text-[#818C96]'>{answer}</p>
                </div>
            </div>
        )
    }
  return (

    <div className='py-[5rem]'>
        <Container>
            <div>
                <HeadingText text='Frequently Asked Questions'/> 
            </div>
            <Swiper
            spaceBetween={10}
            slidesPerView={5}
            autoplay={{ delay: 3000 }}
            pagination={{ clickable: true }}
            breakpoints={{
            
            375: {
                slidesPerView: 1,
            },
            // When window width is >= 1024px
            1024: {
                slidesPerView: 2,
            },

            1536: {
                slidesPerView: 3,
            },
            }}
        >
                    {
                        faqData?.map((item, i)=>{
                            return(
                            <SwiperSlide key={i}>  
                                <FaqCard
                                    key={i}
                                    question={item.question}
                                    answer = {item.answer}
                                />
                            </SwiperSlide>
                            )
                        })
                    }
        </Swiper>            
        </Container>

     
    </div>


  )
}

export default FAQ
