import React from 'react'
import { testimonialData } from '../../../utils/data'
import { HeadingText } from '../../shared/Typographs'
import { Swiper, SwiperSlide } from 'swiper/react';

const Testimonials= () => {
    const TextCard =({name, desc, img})=>{
        return(
            <div className='flex flex-col justify-between w-full md::w-[360px] h-[410px] rounded-[4px] border border-white p-8'>
                <div>
                    <p className='font-[400] font-Rale italic text-[16px] text-white'>{desc}</p>
                </div>

                <div className='flex flex-col justify-center items-center space-y-4'>
                    <img className='object-cover w-[50px] h-[50px] rounded-full' src={img}  alt="" />
                    <p className='text-center'>{name}</p>
                </div>
            </div>
        )
    }
  return (
    <div>
        <div>
            <HeadingText text='Testimonials'/> 
        </div>
        <div className='px-[2rem]'>
            <div className='bg-primary rounded-xl w-full px-6 lg:px-[5rem] py-8 flex justify-center items-center gap-6'>

              <Swiper
            spaceBetween={30}
            slidesPerView={5}
            autoplay={{ delay: 3000 }}
            pagination={{ clickable: true }}
            breakpoints={{
            
            375: {
                slidesPerView: 1,
            },
            768:{
                slidesPerView: 2,
            },
            
            1024: {
                slidesPerView: 3,
            },

            1536: {
                slidesPerView: 3,
            },
            }}
        >
                {
                    testimonialData?.map((item, i)=>{
                        return(
                        <SwiperSlide key={i}>
                            <TextCard
                                key={i}
                                desc={item.desc}
                                name={item.name}
                                img={item.imgUrl}
                            />

                        </SwiperSlide>
                        )
                    })
                }

        </Swiper>               

            </div>        
        </div>        
    </div>


  )
}

export default Testimonials
