import React from 'react'
import { standOutData } from '../../../utils/data'
import StandCard from './StandCard'
import { HeadingText } from '../../shared/Typographs'
import Container from '../../shared/Container'

const StandOut = () => {
  return (
    <div className='w-full py-[5rem]'>
        <Container>
            <div>
                <HeadingText text='What Makes Global Educom Stands Out?'/> 
            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-6 w-full justify-center items-center'>
                {
                standOutData?.map((item, ind)=>{
                    return(
                    <StandCard 
                        icon={item.imgUrl} 
                        title={item.title} 
                        desc={item.desc}
                    />
                    )
                })
                }
            </div>
        </Container>
    </div>
  )
}

export default StandOut