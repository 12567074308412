export const PryButton = (props)=>{
    const {type, text, className, isWhite, isItalic, paddingX, paddingY, textSize } = props

    return(
        <button
        type={type}
        className={`border rounded-[10px] transition-all duration-500 ease-in-out font-Rale font-[500] text-${textSize} ${isItalic ? 'italic' : ''} ${isWhite ? 'bg-white border-primary text-primary' : 'bg-primary text-white border-primary'} ${className}`}
      >
        {text}
      </button>
    )
}
