import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { navLinks } from '../../../utils/data'
import { FaBars, FaTimes } from "react-icons/fa";
import Container from '../../shared/Container';
import { scrollToSection } from '../../../utils/methods';
import { PryButton } from '../../shared/buttons';

const Navbar = () => {
    const [open, setOpen] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
      
        window.addEventListener('scroll', handleScroll);
      
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      

  return (
    <div className={`${isScrolled ? '!bg-white w-full shadow-lg transition-all ease-in-out duration-500 z-[9999999999999]' : ''} fixed  z-[9999999999999] w-full bg-transparent`}>
        
            <div data-aos="flip-up" className={` py-6 relative z-[9999999999999] w-full`}>
                <Container>
                    <div className=' flex w-full justify-between items-center'>

                    <div>
                            <Link to={'/'} className='text-primary font-[900] font-Rale text-[24px] sm:text-[30px]'>
                                GLOBAL EDUCOM
                                {/* <img className='h-[50px]' src={Logo} alt="" /> */}
                            </Link>
                    </div>
                    
                    <ul className='lg:flex gap-8 hidden'>
                            {
                                navLinks?.map((item, id)=>{
                                    return(
                                        <li key={id} className={`text-[#252641] font-[600] font-Raleway text-[16px] leading-9`}><NavLink className={({ isActive, isPending }) =>
                                        isActive ? "text-primary font-bold transition-all ease-out duration-300" : ""} onClick={() => scrollToSection(`${item.id}`)} to={item.url}>{item.name}</NavLink></li>
                                    )
                                })
                            }

                        </ul>



                        {/* desktop */}


                        <div className='block lg:hidden'><button onClick={()=>setOpen(!open)} className='lg:hidden block  top-0 right-0 text-3xl cursor-pointer'>{open? <FaTimes color='#108A00' /> : <FaBars color={isScrolled? '#108A00' : 'white'}/>}</button></div>
                        <div className='hidden lg:flex gap-4'>
                        <PryButton
                            type={'button'}
                            text={'Login'}
                            textSize={12}
                            isItalic={false}
                            isWhite={true}
                            className={'px-[40px] py-[8px]'}
                            />

                        <PryButton
                            type={'button'}
                            text={'Sign Up'}
                            textSize={12}
                            isItalic={false}
                            isWhite={false}
                            className={'px-[40px] py-[8px]'}
                            />
                        </div>                        
                    </div>

                </Container> 

                {/* mobile */}
                    <ul className={` bg-white lg:hidden w-full h-[95vh] lg:h-full top-[80px] lg:top-0 left-0 flex items-center gap-10 lg:space-y-0 lg:space-x-3 absolute lg:relative flex-col lg:flex-row transition-all duration-500 ease-in-out ${open? 'translate-x-[0]' : 'translate-x-[-800px]'} py-10`}>
                        {
                            navLinks?.map((item, id)=>{
                                return(
                                    <li onClick={()=>setOpen(false)} key={id} className={`text-[#252641] font-[600] font-Raleway text-[16px] leading-9 w-full flex justify-center items-center`}><NavLink className={({ isActive, isPending }) =>
                                    isActive ? "text-primary font-bold border-b border-primary w-full flex justify-center items-center transition-all ease-out duration-300" : " w-full flex justify-center items-center"} onClick={() => scrollToSection(`${item.id}`)} to={item.url}>{item.name}</NavLink></li>
                                )
                            })
                        }

<li>
                                <Link to={'/'}>
                                <PryButton
                                    type={'button'}
                                    text={'Login'}
                                    textSize={12}
                                    isItalic={false}
                                    isWhite={true}
                                    className={'px-[60px] py-[8px]'}
                                    />
                                </Link>
                                
                            </li>
                            <li>
                                <Link to={'/'}>
                                    <PryButton
                                    type={'button'}
                                    text={'Sign Up'}
                                    textSize={12}
                                    isItalic={false}
                                    isWhite={false}
                                    className={'px-[60px] py-[8px]'}
                                    />         
                                </Link>
                            </li>
                    </ul>
                


            </div>        
       
    </div>


  )
}

export default Navbar